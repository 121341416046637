body {
    width: 75%;
    margin-left: 12.5%;
    margin-right: 12.5%;
    border-left: 1px solid rgba(0, 225, 255, 0.397);
    border-right: 1px solid rgba(0, 225, 255, 0.397);
    border-bottom: none;
    z-index: 20;
}

@media (max-width: 415px) {
    body {
        width: 100%;
        margin:0;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
}

html {
    margin-top:-10px;
    height: 100%;
}