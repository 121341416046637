.logoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width:50%;
    max-height: 77px;
}


.title {
    padding: 0 6px 0 5px ;
    margin-top: 80px;
    margin-bottom:15px;
    font-size:1.5rem;
    font-family: 'Open Sans', sans-serif;
}


.adress {
    margin: 8px 0 8px 10px;
    font-size:1.1rem;
    padding: 10px 0 0 0;
    font-family: 'Open Sans', sans-serif;
}

.border {
    height: 1px;
    width:100%;
    border-bottom: 1px solid rgba(128, 128, 128, 0.452);
}

.overMap {
    display: flex;
    width: 100%;
    height: 100%;
}

.adressDiv {
    width: 50%;
    height: 100%;
}

.imgDiv {
    width: 50%;
    height: 80%;
}

.img {
    margin-left: 5%;
    margin-top: 100px;
    height: 80%;
    width: 90%;
    border-radius: 5px;
}

@media (max-width: 414px) {
    .overMap {
        flex-direction: column;
        height:100%;
        width: 100%;   
    }

    .border {
        width:100%;
    }

    .title { 
        margin-top: 15px;
    }

    .adressDiv {
        width: 100%;
        height: 100%;
    }

    .adress {
        text-align: center;
    }

    .title {
        text-align: center;
    }

    .imgDiv {
        width: 100%;
        height: 300px;
    }

    .img {
        margin-left:0;
        margin-top:0;
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .logo {
        width:70%;
        max-height: 77px;
    }  
}

