.logoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width:50%;
    max-height: 137px;
    max-width: 560px;
}

.contentDiv {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.contentMiddle {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-top: 20px;
}

.content {
    width: 50%;
}

.contentTextDiv {

    width: 50%;
}

.contentText {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width:100%;
    height: 100%;
    font-size:1.5rem;
    font-family: 'Titillium Web', sans-serif;
    font-style:italic;
    text-align: center;
    margin: 0;
}

.photoLeft {
    width: 95%;
    margin-left: 5%;
    border-radius: 5px;
}

.photoLogo {

    background-image: url('iStock-1172233289.png');
}

.photoRight {
    width: 95%;
    margin-right: 5%;
    border-radius: 5px;
}

@media (max-width: 414px) {
    body {
        width: 100%;
    }
    .logo {
        width: 70%;
        max-height: 77px;
    }

    .firma {
        width: 90%;
        
    }

    .contentDiv {
        display: flex;
        flex-direction:column ;
        width: 100%;
        margin-top: 20px;
    }

    .contentMiddle {
        display: flex;
        flex-direction:column ;
        width: 100%;
        margin-top: 20px;
    }
    
    .content {
        width: 100%;
        height: 50%;
    }
    
    .contentTextDiv {
        height: 50%;
        width: 100%;
    }
    
    .contentText {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width:95%;
        height: 50%;
        font-size:1.2rem;
        padding: 10px;
        font-family: 'Titillium Web', sans-serif;
        text-align: center;
        margin: 0;
    }
    
    .photoLeft {
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }
    
    .photoRight {
        width: 100%;
        border-radius: 5px;
        margin:0
    }
}