
.linkDiv {
    display: flex;
    width: 100%;
    height: 70px;
    border-right: 1px solid rgba(0, 0, 0, 0.26);
    justify-content: space-around;

}

.linkDiv a {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    padding-top:25px;
    font-family: 'Play', sans-serif;
    border-bottom: 1px solid grey;
}

.linkDiv a:hover {
    color: white;
    font-size: 1.5rem;
}



.menu {
    display: flex;
    justify-content: center;
    font-size: x-large;
    width:50%;
    height: 45px;
    margin: 0 25% 0 25%;
    background-color: rgb(0, 225, 255);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: 'Play', sans-serif;
}
/* background-color: rgba(128, 128, 128, 0.486); */

.menu p {
    margin-top: 10px;
    
}

.menu p:hover {
    color: white;
    font-size: 1.6rem;
    margin-bottom: 20px;
}

.menu:hover {
    cursor: pointer;
}