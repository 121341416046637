.logoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width:50%;
    max-width: 560px;
    max-height: 137px;
}

.title {
    font-size: 1.8rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}

.produkt {
    font-size: 1.2rem;
    text-align: left;
    font-family: 'Titillium Web', sans-serif;

}

.opis {
    margin-bottom: 30px;
    width:80%;
    margin-left:10%;
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}


.produkty {
    width:60%;
    margin-left: 33%;
}

.photoDiv{
    display: flex;
    position: relative;
    box-sizing: border-box;
    height: 50%;
}

.photo {
    z-index: 10;
    width:100%;
}

.photoText {
    display: flex;
    position: absolute;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.466);
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.text {
    width: 90%;
    margin-left: 5%;
    height: 50%;
    margin-top: 25%;
    color:white;
    font-size: 1.7rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    font-style:italic;
}

.borderTop{
    width: 30%;
    margin-top: 30px;
    margin-left: 35%;
    border-bottom: 1px solid gray;
}

.borderBottom {
    width: 80%;
    margin-bottom: 30px;
    margin-left: 10%;
    border-bottom: 1px solid gray;
}

@media (max-width: 414px) {
    .logo {
        width:70%;
        max-height: 77px;
    }

    .produkty {
        width:90%;
        margin-left: 8%;
    }

    .photoDiv {
        display: flex;
        height: 100%;
        flex-direction: column-reverse;
    }
    .photo {
        height: 50%;
    }


    .photoText {
        position: static;
        background-color: white;
    }
    .text {
        margin-top: 10px;
        height: 50%;
        color: black;
        font-size: 1.5rem;
       
    }
}