.logoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width:50%;
    max-height: 137px;
    max-width: 560px;
}

.eu {
    margin-top: 30px;
}

.title {
    width: 90%;
    margin-left: 5%;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}

.para {
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}

@media (max-width: 414px) {
    .eu {
        width:90%
    }
    .logo {
        width:70%;
        max-height: 77px;
    }
}