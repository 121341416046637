.logoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width:50%;
    max-height: 137px;
    max-width: 560px;
}

.title {
    width: 90%;
    margin-left: 5%;
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}

.para {
    width: 60%;
    margin-left: 20%;
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;

}

.photoDiv{
    display: flex;
    position: relative;
    box-sizing: border-box;
    height: 50%;
}

.photo {
    z-index: 10;
    width:100%;
}

.photoText {
    display: flex;
    position: absolute;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.466);
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.text {
    width: 90%;
    margin-left: 5%;
    height: 50%;
    margin-top: 20%;
    color:white;
    font-size: 1.7rem;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
   
}

@media (max-width: 414px) {
    body {
        width: 100%;
    }
    .logo {
        width: 70%;
        max-height: 77px;
    }

    .photoDiv {
        display: flex;
        height: 100%;
        flex-direction: column-reverse;
    }
    .photo {
        height: 50%;
    }


    .photoText {
        position: static;
        background-color: white;
    }
    .text {
        margin-top: 10px;
        height: 50%;
        color: black;
        font-size: 1.5rem;
       font-style: italic;
    }
}

@media (max-width: 780px) {
    .text {

        margin-top: 10%;
        font-size: 1.3rem;
       
    }
 }