.SideDrawer {
  position: fixed;
  width: 90%;
  margin: 0 5% 0 5%;
  height: 430px;
  left: 0;
  top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 200;
  background-color: white;
  background-color: rgb(0, 225, 255);
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}
/* background-color: rgb(173, 173, 173); */
@media (min-width: 770px) {
  .SideDrawer {
    width: 40%;
    margin: 0 30% 0 30%;
    border: 1px solid grey;
    border-top: none;
    background-color: rgb(0, 225, 255);
  }
}

.Open {
  transform: translateY(0);
}

.Close {
  transform: translateY(-100%);
}

/* .Logo {
    height: 11%;
    margin-bottom: 32px;
} */
